import {request} from '@/common/request/admin.js'

export const site = {
	upload(data) {
		return request.post('/backend/upload/create', data, {
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			timeout: 60 * 20 * 1000
		})
	}
}

export const attachment = {
	all(params) {
		return request.get('/backend/attachment/all', {params})
	},
}

export const auth = {
	login(data) {
		return request.post('/backend/auth/login', data)
	},
	logout() {
		return request.post('/backend/auth/logout')
	},
	getLoginInfo() {
		return request.post('/backend/auth/getLoginInfo')
	}
}

export const dashboard = {
	stat(params) {
		return request.get('/backend/dashboard/stat', {params})
	},
	check(params) {
		return request.get('/backend/dashboard/check', {params})
	},
	good_type(params) {
		return request.get('/backend/dashboard/good_type', {params})
	},
	good_category(params) {
		return request.get('/backend/dashboard/good_category', {params})
	},
	user_rank(params) {
		return request.get('/backend/dashboard/user_rank', {params})
	},
	department_drop(params) {
		return request.get('/backend/dashboard/department_drop', {params})
	},
	user_task(params) {
		return request.get('/backend/dashboard/user_task', {params})
	},
	department_task(params) {
		return request.get('/backend/dashboard/department_task', {params})
	}
}

export const config = {
	get(params) {
		return request.get('/backend/config/get', {params})
	},
	create(data) {
		return request.post('/backend/config/create', data)
	}
}

export const admin = {
	get(params) {
		return request.get('/backend/admin/get', {params})
	},
	all(params) {
		return request.get('/backend/admin/all', {params})
	},
	create(data) {
		return request.post('/backend/admin/create', data)
	},
	delete(data) {
		return request.post('/backend/admin/delete', data)
	},
	sort(data) {
		return request.post('/backend/admin/sort', data)
	},
	import(data) {
		return request.post('/backend/admin/import', data)
	},
}

export const sensitive = {
	get(params) {
		return request.get('/backend/sensitive/get', {params})
	},
	all(params) {
		return request.get('/backend/sensitive/all', {params})
	},
	create(data) {
		return request.post('/backend/sensitive/create', data)
	},
	delete(data) {
		return request.post('/backend/sensitive/delete', data)
	},
	sort(data) {
		return request.post('/backend/sensitive/sort', data)
	},
	import(data) {
		return request.post('/backend/sensitive/import', data)
	},
}

export const article = {
	get(params) {
		return request.get('/backend/article/get', {params})
	},
	all(params) {
		return request.get('/backend/article/all', {params})
	},
	create(data) {
		return request.post('/backend/article/create', data)
	},
	delete(data) {
		return request.post('/backend/article/delete', data)
	},
	sort(data) {
		return request.post('/backend/article/sort', data)
	},
	import(data) {
		return request.post('/backend/article/import', data)
	},
}

export const user = {
	get(params) {
		return request.get('/backend/user/get', {params})
	},
	all(params) {
		return request.get('/backend/user/all', {params})
	},
	create(data) {
		return request.post('/backend/user/create', data)
	},
	delete(data) {
		return request.post('/backend/user/delete', data)
	},
	sort(data) {
		return request.post('/backend/user/sort', data)
	},
	import(data) {
		return request.post('/backend/user/import', data)
	},
	start(data) {
		return request.post('/backend/user/start', data)
	},
	pause(data) {
		return request.post('/backend/user/pause', data)
	},
}

export const department = {
	get(params) {
		return request.get('/backend/department/get', {params})
	},
	all(params) {
		return request.get('/backend/department/all', {params})
	},
	create(data) {
		return request.post('/backend/department/create', data)
	},
	delete(data) {
		return request.post('/backend/department/delete', data)
	},
	sort(data) {
		return request.post('/backend/department/sort', data)
	},
	import(data) {
		return request.post('/backend/department/import', data)
	},
}

export const category = {
	get(params) {
		return request.get('/backend/category/get', {params})
	},
	all(params) {
		return request.get('/backend/category/all', {params})
	},
	create(data) {
		return request.post('/backend/category/create', data)
	},
	delete(data) {
		return request.post('/backend/category/delete', data)
	},
	sort(data) {
		return request.post('/backend/category/sort', data)
	},
	import(data) {
		return request.post('/backend/category/import', data)
	},
}

export const good = {
	get(params) {
		return request.get('/backend/good/get', {params})
	},
	all(params) {
		return request.get('/backend/good/all', {params})
	},
	create(data) {
		return request.post('/backend/good/create', data)
	},
	delete(data) {
		return request.post('/backend/good/delete', data)
	},
	sort(data) {
		return request.post('/backend/good/sort', data)
	},
	import(data) {
		return request.post('/backend/good/import', data)
	},
	drop(data) {
		return request.post('/backend/good/drop', data)
	},
	qrcode(data) {
		return request.post('/backend/good/qrcode', data)
	}
}

export const good_user = {
	get(params) {
		return request.get('/backend/good_user/get', {params})
	},
	all(params) {
		return request.get('/backend/good_user/all', {params})
	},
	create(data) {
		return request.post('/backend/good_user/create', data)
	},
	delete(data) {
		return request.post('/backend/good_user/delete', data)
	},
	sort(data) {
		return request.post('/backend/good_user/sort', data)
	},
	import(data) {
		return request.post('/backend/good_user/import', data)
	}
}

export const drop = {
	get(params) {
		return request.get('/backend/drop/get', {params})
	},
	all(params) {
		return request.get('/backend/drop/all', {params})
	},
	create(data) {
		return request.post('/backend/drop/create', data)
	},
	delete(data) {
		return request.post('/backend/drop/delete', data)
	},
	sort(data) {
		return request.post('/backend/drop/sort', data)
	},
	import(data) {
		return request.post('/backend/drop/import', data)
	},
	check(data) {
		return request.post('/backend/drop/check', data)
	},
}

export const feedback = {
	get(params) {
		return request.get('/backend/feedback/get', {params})
	},
	all(params) {
		return request.get('/backend/feedback/all', {params})
	},
	create(data) {
		return request.post('/backend/feedback/create', data)
	},
	delete(data) {
		return request.post('/backend/feedback/delete', data)
	},
	sort(data) {
		return request.post('/backend/feedback/sort', data)
	},
	import(data) {
		return request.post('/backend/feedback/import', data)
	},
}

export const plan = {
	get(params) {
		return request.get('/backend/plan/get', {params})
	},
	all(params) {
		return request.get('/backend/plan/all', {params})
	},
	create(data) {
		return request.post('/backend/plan/create', data)
	},
	delete(data) {
		return request.post('/backend/plan/delete', data)
	},
	sort(data) {
		return request.post('/backend/plan/sort', data)
	},
	import(data) {
		return request.post('/backend/plan/import', data)
	},
}

export const task = {
	get(params) {
		return request.get('/backend/task/get', {params})
	},
	all(params) {
		return request.get('/backend/task/all', {params})
	},
	create(data) {
		return request.post('/backend/task/create', data)
	},
	delete(data) {
		return request.post('/backend/task/delete', data)
	},
	sort(data) {
		return request.post('/backend/task/sort', data)
	},
	import(data) {
		return request.post('/backend/task/import', data)
	},
}

export const banner = {
	get(params) {
		return request.get('/backend/banner/get', {params})
	},
	all(params) {
		return request.get('/backend/banner/all', {params})
	},
	create(data) {
		return request.post('/backend/banner/create', data)
	},
	delete(data) {
		return request.post('/backend/banner/delete', data)
	},
	sort(data) {
		return request.post('/backend/banner/sort', data)
	},
	import(data) {
		return request.post('/backend/banner/import', data)
	},
}


export const file = {
	get(params) {
		return request.get('/backend/file/get', {params})
	},
	all(params) {
		return request.get('/backend/file/all', {params})
	},
	create(data) {
		return request.post('/backend/file/create', data)
	},
	delete(data) {
		return request.post('/backend/file/delete', data)
	},
	sort(data) {
		return request.post('/backend/file/sort', data)
	},
	import(data) {
		return request.post('/backend/file/import', data)
	},
}

export const template = {
	get(params) {
		return request.get('/backend/template/get', {params})
	},
	all(params) {
		return request.get('/backend/template/all', {params})
	},
	create(data) {
		return request.post('/backend/template/create', data)
	},
	delete(data) {
		return request.post('/backend/template/delete', data)
	},
	sort(data) {
		return request.post('/backend/template/sort', data)
	},
	import(data) {
		return request.post('/backend/template/import', data)
	},
	wechat(params) {
		return request.get('/backend/template/wechat', {params})
	}
}
